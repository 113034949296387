<template>
  <div class="settings-account">
    <Loader v-if="isLoading" :text="loadingMessage" />

    <v-alert
      v-if="user?.forceResetPassword"
      type="info"
      prominent
      class="mb-4"
    >
      For security purposes, you need to change your password. Use your
      autogenerated temporary password as the "Old Password" to set a new
      password. You should find the temporary password in your email.
    </v-alert>

    <!-- Profile -->
    <v-card elevation="0" class="my-2">
      <v-card-subtitle class="text-h5"> Your Profile </v-card-subtitle>
      <v-card-text>
        <ValidationObserver>
          <v-row>
            <v-col cols="12" md="4">
              <ValidationProvider
                name="Email"
                rules="required|email"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Email"
                  name="email"
                  type="email"
                  v-on:keyup="updateProfileData"
                  v-model="tempProfile.email"
                >
                </v-text-field>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider
                name="First Name"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="First Name"
                  name="firstName"
                  v-on:keyup="updateProfileData"
                  v-model="tempProfile.firstName"
                >
                </v-text-field>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider
                name="Last Name"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Last Name"
                  name="lastName"
                  v-on:keyup="updateProfileData"
                  v-model="tempProfile.lastName"
                >
                </v-text-field>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                color="primary"
                @click.prevent="onSubmitProfile"
                :disabled="
                  !tempProfile.email ||
                  !tempProfile.firstName ||
                  !tempProfile.lastName
                "
              >
                Update Profile
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-card>

    <!-- Password Reset Section -->
    <v-card elevation="0" class="my-2">
      <v-card-subtitle class="text-h5"> Password Reset </v-card-subtitle>
      <v-card-text>
        <ValidationObserver>
          <v-row>
            <v-col cols="12" sm="6">
              <ValidationProvider
                name="Old password"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Old Password"
                  name="oldPassword"
                  type="password"
                  v-model="oldPass"
                  autocomplete="off"
                >
                </v-text-field>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" sm="6">
              <ValidationProvider
                name="New password"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="New Password"
                  name="newPassword"
                  type="password"
                  v-model="newPass"
                >
                </v-text-field>
                <div class="invalid-data">{{ errors[0] }}</div>
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn
                color="primary"
                @click.prevent="onUpdatePassword"
                :disabled="!oldPass || !newPass"
              >
                Update Password
              </v-btn>
            </v-col>
          </v-row>
        </ValidationObserver>
      </v-card-text>
    </v-card>

    <!-- Two Factor -->
    <v-card elevation="0" v-if="showBanner" class="my-2">
      <v-card-subtitle class="text-h5">
        Manage Two Factor Authentication
      </v-card-subtitle>
      <v-switch
        class="px-5 my-5"
        :input-value="!!user?.enabled2FA"
        :label="`${!!user?.enabled2FA ? 'Enabled' : 'Disabled'}`"
        @change="toggleTwoFactor"
      ></v-switch>
    </v-card>

    <!-- Company Profile -->
    <div class="settings-block" v-if="is_privileged">
      <div class="settings-block__header">Company Profile</div>
      <button
        class="btn settings-block__edit-btn settings-block__edit-btn-success"
        v-if="companySettingsEditable"
        v-on:click.prevent="saveCompanySettings"
      >
        Save
      </button>
      <button
        class="btn settings-block__edit-btn settings-block__edit-btn-cancel"
        v-if="companySettingsEditable"
        v-on:click.prevent="editCompanySettings"
      >
        Cancel
      </button>
      <button
        class="btn settings-block__edit-btn"
        v-if="!companySettingsEditable"
        v-on:click.prevent="editCompanySettings"
      >
        Edit
      </button>
      <div class="settings-block__company-profile">
        <ValidationObserver>
          <form @submit.prevent="onUpdateCompanyProfile">
            <table>
              <tbody>
                <tr>
                  <td>
                    <div class="form-group">
                      <ValidationProvider
                        name="CustomerName"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          label="Name"
                          name="CustomerName"
                          v-bind:disabled="!companySettingsEditable"
                          v-on:keyup="updateCompanyData"
                          v-model="tempCustomer.CustomerName"
                        >
                        </v-text-field>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                  <td rowspan="3">
                    <div class="form-group">
                      <label
                        for="address"
                        style="font-size: 12px; color: rgb(0, 0, 0, 0.6)"
                      >
                        Company Logo
                      </label>
                      <div
                        class="settings__logo"
                        v-bind:class="{ editable: companySettingsEditable }"
                      >
                        <img
                          v-bind:src="
                            updatedDataCustomer.logo || tempCustomer.logo
                          "
                        />
                        <div
                          class="settings__upload"
                          v-if="companySettingsEditable"
                        >
                          <span>Upload File</span>
                          <input
                            type="file"
                            name="logo"
                            v-on:change="encodeFile"
                          />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group">
                      <ValidationProvider
                        name="ContactName"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          label="Contact Name"
                          name="ContactName"
                          v-bind:disabled="!companySettingsEditable"
                          v-on:keyup="updateCompanyData"
                          v-model="tempCustomer.ContactName"
                        >
                        </v-text-field>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group">
                      <ValidationProvider
                        name="Address"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          label="Address"
                          name="address"
                          v-bind:disabled="!companySettingsEditable"
                          v-on:keyup="updateCompanyData"
                          v-model="tempCustomer.Address"
                        >
                        </v-text-field>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="form-group">
                      <ValidationProvider
                        name="PhoneNumber"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          label="Phone Number"
                          name="PhoneNumber"
                          v-bind:disabled="!companySettingsEditable"
                          v-on:keyup="updateCompanyData"
                          v-model="tempCustomer.PhoneNumber"
                        >
                        </v-text-field>
                        <div class="invalid-data">{{ errors[0] }}</div>
                      </ValidationProvider>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!-- Locations -->
    <div class="settings-block" v-if="is_privileged">
      <div class="settings-block__header">Locations</div>
      <!-- <button class="btn settings-block__edit-btn">Edit</button> -->
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <th class="text-left">ID</th>
            <th class="text-left">NAME</th>
            <th class="text-left">ADDRESS</th>
            <th class="text-left">CITY</th>
            <th class="text-left">PHONE</th>
            <th class="text-left">STATUS</th>
            <th class="text-left"></th>
          </thead>
          <tbody>
            <template v-for="(location, index) in customer.Locations">
              <tr
                class="settings-block__item"
                :class="{
                  'settings-block__item-selected': locationItemView === index,
                }"
                :key="index"
              >
                <td>{{ location.locationID || '' }}</td>
                <td>{{ location.locationName || '' }}</td>
                <td>{{ location.LocationAddress1 || '' }}</td>
                <td>{{ location.City || '' }}</td>
                <td>{{ location.PhoneNumber || '' }}</td>
                <td>Add status</td>
                <td>
                  <button
                    class="btn settings-block__edit"
                    type="button"
                    v-if="locationItemView !== index"
                    v-on:click.prevent="editLocationSettings($event, index)"
                  >
                    Edit
                  </button>
                  <button
                    class="btn settings-block__edit settings-block__edit-success"
                    v-else
                    @click.prevent="onSubmitLocation(index)"
                  >
                    Save
                  </button>
                  <br />
                  <button
                    class="btn settings-block__edit"
                    type="button"
                    v-if="locationItemView === index"
                    v-on:click.prevent="editLocationSettings($event, index)"
                  >
                    Cancel
                  </button>
                </td>
              </tr>
              <tr
                v-if="locationItemView === index"
                :key="`_${index}`"
                class="settings-block__location-form"
              >
                <td colspan="7">
                  <div class="settings-block__item-details">
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Location ID:"
                        name="locationID"
                        pattern="^[ 0-9]+$"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.locationID"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Location Name:"
                        name="locationName"
                        maxlength="255"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.locationName"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Time Zone:"
                        name="timeZone"
                        maxlength="255"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.timeZone"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="EMR Location ID:"
                        name="EMRLocationID"
                        pattern="^[ 0-9]+$"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.EMRLocationID"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Is Practice:"
                        name="IsPractice"
                        maxlength="255"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.IsPractice"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="NPI:"
                        name="NPI"
                        pattern="^[ 0-9]+$"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.NPI"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Location Adreass 1:"
                        name="LocationAddress1"
                        maxlength="255"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.LocationAddress1"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Location Adreass 2:"
                        name="LocationAddress2"
                        maxlength="255"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.LocationAddress2"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="City:"
                        name="City"
                        maxlength="50"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.City"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="City:"
                        name="State"
                        maxlength="50"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.State"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Postal Code:"
                        name="PostalCode"
                        pattern="^[ 0-9]+$"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.PostalCode"
                      >
                      </v-text-field>
                    </div>
                    <div class="settings-item settings-item_c4of12">
                      <v-text-field
                        label="Phone Number:"
                        name="PhoneNumber"
                        pattern="^[ 0-9]+$"
                        v-on:keyup="updateLocationData($event, index)"
                        v-model="locationDetail.PhoneNumber"
                      >
                      </v-text-field>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Loader from '@/components/loader/loader.vue';

const { mapGetters: customerGetters, mapActions: customerActions } =
  createNamespacedHelpers('customer');

export default {
  name: 'SettingsAccount',
  props: {},
  components: {
    ValidationProvider,
    ValidationObserver,
    Loader,
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      user: 'USER',
    }),
    is_privileged() {
      return !!this.user?.is_privileged;
    },
    showBanner() {
      if (
        this.user?.enabled2FA !== null &&
        this.user?.enabled2FA !== undefined
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      companySettingsEditable: false,
      locationDetail: null,
      locationItemView: null,
      tempCustomer: {},
      updatedDataCustomer: {},
      tempProfile: {
        email: '',
        firstName: '',
        lastName: '',
      },
      updatedDataProfile: {},
      tempLocation: {},
      oldPass: '',
      newPass: '',
      showPassForm: false,
      isLoading: false,
      loadingMessage: '',
    };
  },
  watch: {
    user: {
      handler() {
        this.tempProfile = {
          email: this.user?.email,
          firstName: this.user?.firstName,
          lastName: this.user?.lastName,
        };
        this.tempCustomer = Object.assign({}, this.customer);
      },
      deep: true,
    },
  },
  methods: {
    ...customerActions(['toggleTwoFactor', 'getLoggedInUser']),
    onUpdatePassword() {
      if (!this.newPass && !this.oldPass) return;
      this.isLoading = true;
      this.loadingMessage = 'Updating data...';

      window.axios
        .post('/proxy/change-customer-user-credentials', {
          customer_id: this.customer.customer_id,
          oldPassword: this.oldPass,
          newPassword: this.newPass,
        })
        .then(() => {
          this.oldPass = '';
          this.newPass = '';
          this.showPassForm = false;
          return this.getLoggedInUser();
        })
        .catch((e) => {
          console.log('error: ', e);
        })
        .finally(() => {
          this.isLoading = false;
          this.loadingMessage = '';
        });
    },
    saveCompanySettings() {
      this.companySettingsEditable = !this.companySettingsEditable;
      this.onSubmitCustomer();
    },
    editCompanySettings(e) {
      if (!e || e.target.desabled) {
        return;
      }
      this.updatedDataCustomer = {};
      this.tempCustomer = Object.assign({}, this.customer);
      this.companySettingsEditable = !this.companySettingsEditable;
    },
    editLocationSettings(e, index) {
      if (e && e.target && e.target.desabled) {
        return;
      }
      this.locationItemView = index !== this.locationItemView ? index : null;
      this.locationDetail = Object.assign({}, this.customer.Locations[index]);
      this.tempLocation = {};
    },
    encodeFile(e) {
      let input = e.target,
        file = input.files[0],
        reader = new FileReader();
      reader.onloadend = () => {
        this.updatedDataCustomer = {
          ...this.updatedDataCustomer,
          logo: reader.result,
        };
      };

      reader.readAsDataURL(file);
    },
    updateProfileData(e) {
      let el = e.target;
      this.updatedDataProfile[el.name] = el.value;
    },
    updateCompanyData(e) {
      let el = e.target;
      if (el.value != this.customer[el.name]) {
        this.updatedDataCustomer = {
          ...this.updatedDataCustomer,
          [el.name]: el.value,
        };
      } else {
        if (this.updatedDataCustomer[el.name]) {
          delete this.updatedDataCustomer[el.name];
        }
      }
    },
    updateLocationData(e, index) {
      let el = e.target,
        { Locations } = this.customer;
      if (el.value != Locations[index][el.name]) {
        this.tempLocation = {
          ...this.tempLocation,
          [el.name]: el.value,
        };
      } else {
        if (this.tempLocation[el.name]) {
          delete this.tempLocation[el.name];
        }
      }
    },
    onSubmitProfile() {
      this.isLoading = true;
      this.loadingMessage = 'Updating data...';

      window.axios
        .post(
          '/proxy/change-customer-user-credentials',
          this.updatedDataProfile
        )
        .then(() => this.getLoggedInUser)
        .finally(() => {
          this.isLoading = false;
          this.loadingMessage = '';
        });
    },
    onSubmitCustomer() {
      if (Object.keys(this.updatedDataCustomer).length) {
        this.isLoading = true;
        this.loadingMessage = 'Updating data...';
        window.axios
          .post('/proxy/change-customer-info', {
            updated_type: 'customer',
            updated_data: this.updatedDataCustomer,
            destination_id: this.customer.customer_id,
          })
          .then((response) => {
            if (response.data && response.data.success === true) {
              this.$store.commit('customer/UPD_CUSTOMER', this.tempCustomer);
              this.editCompanySettings();
            }
            this.isLoading = false;
            this.loadingMessage = '';
          });
      } else {
        this.editCompanySettings();
      }
    },
    onSubmitLocation(index) {
      if (Object.keys(this.tempLocation).length) {
        this.isLoading = true;
        this.loadingMessage = 'Updating data...';
        window.axios
          .post('/proxy/change-customer-info', {
            updated_type: 'location',
            updated_data: this.tempLocation,
            destination_id: this.customer.Locations[index].id,
          })
          .then((response) => {
            if (response.data && response.data.success === true) {
              this.$store.commit('customer/UPD_LOCATIONS', {
                index,
                location: this.locationDetail,
              });
              this.editLocationSettings(null);
            }
            this.isLoading = false;
            this.loadingMessage = '';
          });
      } else {
        this.editLocationSettings(null);
      }
    },
  },
};
</script>
